var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { FORM_DEFAULT_COLOR } from './FormBlock';
var FormModal = function (_a) {
    var id = _a.id, isOpen = _a.isOpen, onRequestClose = _a.onRequestClose, block = _a.block;
    var styles = {
        content: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      position: absolute;\n      padding: 2em 4em;\n      box-shadow: 0px 0px 16px -6px rgba(0, 0, 0, 0.6);\n      background-color: white;\n      top: 50%;\n      left: 50%;\n      right: auto;\n      bottom: auto;\n      margin-right: -50%;\n      transform: translate(-50%, -50%);\n    "], ["\n      position: absolute;\n      padding: 2em 4em;\n      box-shadow: 0px 0px 16px -6px rgba(0, 0, 0, 0.6);\n      background-color: white;\n      top: 50%;\n      left: 50%;\n      right: auto;\n      bottom: auto;\n      margin-right: -50%;\n      transform: translate(-50%, -50%);\n    "]))),
        button: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      padding: 1em 3em;\n      margin-top: 1em;\n      background-color: ", ";\n      border-radius: 0.25rem;\n      color: white;\n      &:hover {\n        opacity: 0.9;\n      }\n    "], ["\n      padding: 1em 3em;\n      margin-top: 1em;\n      background-color: ", ";\n      border-radius: 0.25rem;\n      color: white;\n      &:hover {\n        opacity: 0.9;\n      }\n    "])), block.submitBtnColor
            ? block.submitBtnColor
            : FORM_DEFAULT_COLOR),
    };
    window.dataLayer = window.dataLayer || [];
    useEffect(function () {
        if (isOpen) {
            // isOpen の状態が変化したときに実行する
            // モーダルが表示されたときにカスタムイベントを発火
            window.dataLayer.push({
                event: 'formSubmit',
                modalId: id,
            });
        }
    }, [isOpen]);
    return (_jsx(Modal, __assign({ isOpen: isOpen, onRequestClose: onRequestClose, contentLabel: 'Modal', css: styles.content, shouldCloseOnOverlayClick: true, ariaHideApp: false, id: id }, { children: _jsxs("div", __assign({ className: 'text-center' }, { children: [_jsx("h1", { children: "\u9001\u4FE1\u5B8C\u4E86\u3057\u307E\u3057\u305F" }), _jsx("button", __assign({ css: styles.button, onClick: onRequestClose }, { children: "\u9589\u3058\u308B" }))] })) })));
};
export default FormModal;
var templateObject_1, templateObject_2;
