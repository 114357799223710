import { useCallback, useEffect, useState } from 'react';
export var useWindowSize = function () {
    var _a = useState({
        width: 0,
        height: 0,
    }), windowSize = _a[0], setWindowSize = _a[1];
    useEffect(function () {
        setWindowSize({
            width: document.body.clientWidth,
            height: document.body.clientHeight,
        });
        handleResize();
        return function () { return window.removeEventListener('resize', handleResize); };
    }, []);
    var handleResize = useCallback(function () {
        window.addEventListener('resize', function () {
            setWindowSize({
                width: document.body.clientWidth,
                height: document.body.clientHeight,
            });
        });
    }, [windowSize.width, windowSize.height]);
    return windowSize;
};
