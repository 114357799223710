export var CMS_IMAGE_PREVIEW_BASE_URL = 'https://cms-image.locaop.jp/preview';
export var isAlignType = function (value) {
    return (typeof value === 'string' &&
        (value === 'left' || value === 'center' || value === 'right'));
};
var imageAreaAlignList = ['left', 'right'];
export var isImageAreaAlignType = function (obj) {
    return imageAreaAlignList.includes(obj);
};
var imageAreaAlignForSPList = ['top', 'bottom'];
export var isImageAreaAlignForSPType = function (obj) {
    return imageAreaAlignForSPList.includes(obj);
};
export var formFieldTypes = [
    'text',
    'textarea',
    'email',
    'datetime',
    'radio',
    'checkbox',
];
export var isOptionsFormFieldType = function (type) {
    return type === 'radio' || type === 'checkbox';
};
export var isOptionsFormField = function (field) {
    var options = field.options;
    if (!options || !Array.isArray(options) || !isOptionsFormFieldType(field)) {
        return false;
    }
    return options.every(function (option) { return isFormOption(option); });
};
export var isFormOption = function (option) {
    return option.label !== undefined;
};
export var SUFFIX_GENERATED_WEBP_FILENAME = ".genereted.webp";
export var getGeneratedWebpPath = function (originalUploadFilePath, width, height) {
    return "".concat(originalUploadFilePath, ".").concat(width, "x").concat(height).concat(SUFFIX_GENERATED_WEBP_FILENAME);
};
export var isCustomDomain = function (domain) {
    if (!domain) {
        return false;
    }
    return !['site.locaop.jp', 'localhost', 'stg-site.locaop.jp'].includes(domain);
};
export var ALL_SITE_DB_COLUMN_TYPES = ['text', 'image'];
