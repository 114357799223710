var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { replaceUrl } from '../utils/url';
var isDesktop = function (width) {
    return width >= 1024;
};
var EmbeddedIframe = function (_a) {
    var block = _a.block;
    var _b = useState(window.innerWidth), windowWidth = _b[0], setWindowWidth = _b[1];
    var searchParams = new URL(window.location.href).searchParams;
    var inflowSource = searchParams.get('_src') || window.document.referrer;
    var htmlString = block.html;
    var re = /<iframe[^>]+src="([^">]+)/g;
    var results = re.exec(htmlString);
    useEffect(function () {
        window.addEventListener('resize', function () {
            setWindowWidth(window.innerWidth);
        });
    }, []);
    var url = results !== null && results[1];
    var height = isDesktop(windowWidth)
        ? block.height.desktop
        : block.height.mobile;
    return (_jsx("section", __assign({ className: 'w-full lg:w-3/4 my-4 m-auto' }, { children: _jsx("div", __assign({ className: 'aspect-w-16 aspect-h-9', style: { height: height } }, { children: _jsx("iframe", { loading: 'lazy', title: '\u57CB\u3081\u8FBC\u307F\u30B3\u30F3\u30C6\u30F3\u30C4', src: url === false ? '' : replaceUrl(url, inflowSource), frameBorder: '0', allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture', allowFullScreen: true, style: { height: height } }) })) })));
};
export default EmbeddedIframe;
