var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import React from 'react';
import Img from './Img';
import SvgIcon from './SvgIcon';
var LinkButton = function (_a) {
    var linkCount = _a.linkCount, imageUrl = _a.imageUrl, url = _a.url, onClick = _a.onClick, text = _a.text, description = _a.description, icon = _a.icon, color = _a.color, icons = _a.icons;
    var buttonStyle = linkCount === 1
        ? 'hover:opacity-80 shadow-md max-w-sm w-11/12 lg:w-full py-4 px-6 font-bold text-2xl rounded-lg bg-white group relative duration-300 inline-flex items-center hover:shadow-lg text-left'
        : 'hover:opacity-80 shadow-md w-full h-full min-h-full py-4 px-6 font-bold text-2xl rounded-lg bg-white group relative duration-300 inline-flex items-center hover:shadow-lg text-left';
    return (_jsx(_Fragment, { children: _jsx("div", { children: _jsx("a", __assign({ href: url, onClick: function (event) { return onClick(event, url, url); } }, { children: _jsxs("button", __assign({ className: buttonStyle, css: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n              min-height: 1rem;\n            "], ["\n              min-height: 1rem;\n            "]))) }, { children: [icon !== '' && (_jsx("div", { children: _jsx("div", __assign({ className: "text-5xl mr-4" }, { children: _jsx(SvgIcon, { icon: icon, color: color, fontSize: 43, icons: icons }) })) })), imageUrl !== undefined && (_jsx("div", { children: _jsx("p", __assign({ className: 'mr-4 w-16' }, { children: _jsx(Img, { className: 'w-16 h-16 rounded-lg', src: imageUrl, alt: '', loading: 'lazy' }) })) })), _jsxs("div", __assign({ css: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n                color: ", ";\n              "], ["\n                color: ", ";\n              "])), color) }, { children: [text && _jsx("p", { children: text }), description && (_jsx("p", __assign({ className: 'text-base font-normal break-words whitespace-pre-wrap' }, { children: description })))] })), _jsx("svg", __assign({ css: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n                color: ", ";\n              "], ["\n                color: ", ";\n              "])), color), className: 'absolute w-5 h-5 top-1/2 transform -translate-y-1/2 right-2 group-hover:translate-x-1 duration-300', fill: 'currentColor', viewBox: '0 0 20 20', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { fillRule: 'evenodd', d: 'M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z', clipRule: 'evenodd' }) }))] })) })) }) }));
};
export default LinkButton;
var templateObject_1, templateObject_2, templateObject_3;
