var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import React from 'react';
import { scrollMessageSenderStyle, createScrollMessageSender, } from '../../../utils/scroll-message-sender';
import { BgImageBlock } from '../components/BgImageBlock';
import { BgSpImageBlock } from '../components/BgSpImageBlock';
import { CatchBlock } from '../components/CatchBlock';
import { DescriptionBlock } from '../components/DescriptionBlock';
import { IconImageBlock } from '../components/IconImageBlock';
import { RightAreaTextBlock } from '../components/RightAreaTextBlock';
import { TitleBlock } from '../components/TitleBlock';
var HeroBlock = function (_a) {
    var setting = _a.setting;
    var bgImage = setting.bgImage, bgSpImage = setting.bgSpImage, headerFrag = setting.headerFrag, iconImage = setting.iconImage, description = setting.description, title = setting.title, catchphrase = setting.catchphrase;
    var isHeaderDisabled = !headerFrag;
    return (_jsxs("section", __assign({ className: 'wrapper_block hero_block' }, { children: [_jsx(BgImageBlock, { bgImage: bgImage }), _jsx(BgSpImageBlock, { bgSpImage: bgSpImage !== null && bgSpImage !== void 0 ? bgSpImage : bgImage }), _jsx(CatchBlock, { catchphrase: catchphrase }), _jsx(IconImageBlock, { iconImage: iconImage, bgImage: bgImage, isHeaderDisabled: isHeaderDisabled }), _jsxs("div", __assign({ className: 'w-11/12 lg:w-3/4 m-auto mt-5 mb-8', css: scrollMessageSenderStyle(), onClick: createScrollMessageSender('site-edit') }, { children: [_jsx(TitleBlock, { title: title, isHeaderDisabled: isHeaderDisabled }), _jsx(DescriptionBlock, { description: description })] })), _jsx(RightAreaTextBlock, { catchphrase: catchphrase })] })));
};
export default HeroBlock;
