var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import React from 'react';
// タイトル
export var TitleBlock = function (_a) {
    var title = _a.title, isHeaderDisabled = _a.isHeaderDisabled;
    if (isHeaderDisabled && title) {
        return _jsx("h1", __assign({ className: 'text-2xl font-bold my-4' }, { children: title }));
    }
    else
        return null;
};
