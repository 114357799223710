var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import useTrackInflowSource from '../api/use-inflow-source';
import useSite from '../api/use-site';
import { Loading } from '../components/Loading';
import { SiteAccessPassword } from '../components/SiteAccessPassword';
import useSiteDesign from '../hooks/useSiteDesign';
import '../index.scss';
import SitePage from '../pages/SitePage';
import { getParam } from '../utils/getParam';
var styles = {
    errorContainer: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: calc(100vh - 64px);\n  "], ["\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: calc(100vh - 64px);\n  "]))),
    errorTitle: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    font-size: 4rem;\n    font-weight: bold;\n    text-align: center;\n    margin: 2rem 0;\n  "], ["\n    font-size: 4rem;\n    font-weight: bold;\n    text-align: center;\n    margin: 2rem 0;\n  "]))),
    errorMessage: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    font-size: 1.2rem;\n    text-align: center;\n  "], ["\n    font-size: 1.2rem;\n    text-align: center;\n  "]))),
};
var SitePageContainer = function () {
    var siteUid = window.location.pathname.substring(1);
    var domain = window.location.hostname;
    var path = window.location.pathname;
    var siteTemplateKey = new URLSearchParams(window.location.search).get('_siteTemplateKey');
    var recordKey = new URLSearchParams(window.location.search).get('recordKey');
    var _a = useSite(siteUid, domain, path, siteTemplateKey, recordKey), originalSetting = _a.setting, locationJson = _a.locationJson, shopReviews = _a.shopReviews, originalIcons = _a.icons, seoSetting = _a.seoSetting, isLoadingSite = _a.isLoadingSite, authorized = _a.authorized, fetchProtectedSite = _a.fetchProtectedSite, errorTitle = _a.errorTitle, errorMessage = _a.errorMessage;
    var _b = useState(originalSetting), setting = _b[0], setSiteSetting = _b[1];
    var _c = useState(originalIcons), icons = _c[0], setIcons = _c[1];
    var _d = useState(false), isPasswordIncorrect = _d[0], setIsPasswordIncorrect = _d[1];
    var progressSiteDesign = useSiteDesign(setting).progressSiteDesign;
    var trackInflowSource = useTrackInflowSource().trackInflowSource;
    var searchParams = new URL(window.location.href).searchParams;
    var inflowSource = searchParams.get('_src') || window.document.referrer;
    var showPreviewAlert = inflowSource === 'プレビュー';
    useEffect(function () {
        window.addEventListener('message', function (e) {
            if (e.origin === process.env.REACT_APP_PREVIEW_MSG_SENDER_ORIGIN) {
                var _a = e.data, setting_1 = _a.setting, icons_1 = _a.icons;
                setSiteSetting(setting_1);
                setIcons(icons_1);
            }
        });
    }, []);
    useEffect(function () {
        setSiteSetting(originalSetting);
    }, [originalSetting]);
    useEffect(function () {
        setIcons(originalIcons);
    }, [originalIcons]);
    var mode = getParam('mode');
    useEffect(function () {
        if (mode === 'preview') {
            return;
        }
        trackInflowSource(siteUid, inflowSource, domain, path);
    }, []);
    useEffect(function () {
        if (isLoadingSite || !window.location.hash) {
            return;
        }
        var scrollToTargetElement = function () {
            var h = window.location.hash;
            window.location.hash = '';
            window.location.hash = h;
        };
        // 最初の5秒間はリサイズを監視して再スクロールする
        var resizeHandler = function () {
            scrollToTargetElement();
        };
        var resizeObserver = new ResizeObserver(resizeHandler);
        resizeObserver.observe(document.documentElement);
        setTimeout(function () {
            resizeObserver.disconnect();
        }, 5000);
        scrollToTargetElement();
        return function () {
            resizeObserver.disconnect();
        };
    }, [isLoadingSite]);
    var previewAlert = function () {
        return (_jsx("div", __assign({ style: {
                fontSize: '16px',
                textAlign: 'center',
                width: '100%',
                padding: '8px',
                marginTop: '64px',
                backgroundColor: '#ffb74e',
            } }, { children: "\u3053\u308C\u306F\u30B5\u30A4\u30C8\u306E\u30D7\u30EC\u30D3\u30E5\u30FC\u8868\u793A\u3067\u3059\u3002\u30B5\u30A4\u30C8\u306E\u8868\u793A\u56DE\u6570\u306B\u306F\u30AB\u30A6\u30F3\u30C8\u3055\u308C\u307E\u305B\u3093\u3002\u516C\u958B\u7528\u306EURL\u306F\u7BA1\u7406\u753B\u9762\u306E\u300C\u30B5\u30A4\u30C8URL\u306E\u5171\u6709\u300D\u304B\u3089\u53D6\u5F97\u3057\u3066\u304F\u3060\u3055\u3044\u3002" })));
    };
    if (isLoadingSite) {
        return _jsx(Loading, { primaryColor: '#777' });
    }
    // サイト新規作成におけるテンプレート選択画面でプレビューした場合は 404 が返るので、その場合は無視する
    if (errorMessage && mode !== 'preview') {
        return (_jsxs("div", __assign({ css: styles.errorContainer }, { children: [_jsx("h1", __assign({ css: styles.errorTitle }, { children: errorTitle })), _jsx("p", __assign({ css: styles.errorMessage }, { children: errorMessage }))] })));
    }
    if (!authorized && mode !== 'preview') {
        return (_jsx(SiteAccessPassword, { onSubmit: function (password) {
                fetchProtectedSite(password);
                setIsPasswordIncorrect(true);
            }, isPasswordIncorrect: isPasswordIncorrect }));
    }
    if (progressSiteDesign) {
        return _jsx(Loading, { primaryColor: '#777' });
    }
    if (!setting) {
        if (!isLoadingSite && mode !== 'preview') {
            return _jsx("div", { children: "\u30B5\u30A4\u30C8\u304C\u5B58\u5728\u3057\u306A\u3044\u304B\u3001\u8AAD\u307F\u8FBC\u3081\u307E\u305B\u3093\u3067\u3057\u305F\u3002" });
        }
        else {
            return _jsx(Loading, { primaryColor: '#777' });
        }
    }
    return (_jsxs(_Fragment, { children: [showPreviewAlert && previewAlert(), _jsx(SitePage, { uid: siteUid, locationJson: locationJson && locationJson, setting: setting, seoSetting: seoSetting, shopReviews: shopReviews, icons: icons })] }));
};
export default SitePageContainer;
var templateObject_1, templateObject_2, templateObject_3;
