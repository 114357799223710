import { SUFFIX_GENERATED_WEBP_FILENAME } from '../@interfaces/site';
var isSupportedWebP = (function () {
    var elem = window.document.createElement('canvas');
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!(elem.getContext && elem.getContext('2d'))) {
        return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
    }
    return false;
})();
export var isGeneratedWebpPath = function (filePath) {
    return filePath.endsWith(SUFFIX_GENERATED_WEBP_FILENAME);
};
/**
 * 使用しているブラウザのサポートしている画像のURLを返します。
 *
 * @param url 元のURL
 * @returns サポートされた画像のURL
 */
export var getSupportedImageMeta = function (filePath) {
    if (!isGeneratedWebpPath(filePath)) {
        return {
            url: filePath,
        };
    }
    var parts = /(.+)[.]([0-9]+)[x]([0-9]+)?[.]genereted[.]webp$/.exec(filePath);
    if (!parts) {
        return {
            url: filePath,
        };
    }
    if (!isSupportedWebP) {
        return { url: parts[1] };
    }
    var width, height;
    try {
        width = parseInt(parts[2]);
        height = parseInt(parts[3]);
    }
    catch (e) {
        return {
            url: filePath,
        };
    }
    if (width === 0 || height === 0) {
        return {
            url: filePath,
        };
    }
    return {
        width: width,
        height: height,
        url: filePath,
    };
};
