var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
/* eslint-disable no-case-declarations */
import { css } from '@emotion/react';
import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactLoading from 'react-loading';
import { isCustomDomain, } from '../@interfaces/site';
import { API_END_POINT } from '../api/api';
import { lineBreakToParagraphAndHtmltoBrTag } from '../utils/browserUtils';
import { getParam } from '../utils/getParam';
import FormModal from './Modal';
var styles = {
    selectArea: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding: 0.5em 1em;\n    color: black;\n    width: 100%;\n  "], ["\n    padding: 0.5em 1em;\n    color: black;\n    width: 100%;\n  "]))),
    selectOption: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    padding: 0.5em 1em 0.5em 0;\n  "], ["\n    padding: 0.5em 1em 0.5em 0;\n  "]))),
    privacyPolicyTextArea: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    width: 100%;\n    resize: none;\n    padding: 8px;\n  "], ["\n    width: 100%;\n    resize: none;\n    padding: 8px;\n  "]))),
    privacyPolicyWrapper: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    text-align: center;\n  "], ["\n    text-align: center;\n  "]))),
    privacyPolicyLink: css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    display: inline-block;\n    vertical-align: middle;\n    margin: 16px auto;\n    text-decoration: underline;\n    text-align: center;\n    font-size: 18px;\n    font-weight: bold;\n  "], ["\n    display: inline-block;\n    vertical-align: middle;\n    margin: 16px auto;\n    text-decoration: underline;\n    text-align: center;\n    font-size: 18px;\n    font-weight: bold;\n  "]))),
    privacyPolicyCheckbox: function (submitBtnColor) { return css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    position: relative;\n    display: inline-block;\n    vertical-align: middle;\n    padding: 10px;\n    border-radius: 6px;\n    border: 2px solid #c1c1c1;\n    cursor: pointer;\n    margin-right: 12px;\n\n    input {\n      display: none;\n    }\n\n    &.checked {\n      background-color: ", ";\n      color: #ffffff;\n\n      &::before {\n        content: '';\n        position: absolute;\n        top: calc(50% + 3px);\n        left: calc(50% - 4px);\n        transform: translate(-50%, -50%) rotate(45deg);\n        width: 8px;\n        height: 2px;\n        background-color: #ffffff;\n      }\n\n      &::after {\n        content: '';\n        position: absolute;\n        top: 50%;\n        left: calc(50% + 3px);\n        transform: translate(-50%, -50%) rotate(-45deg);\n        width: 14px;\n        height: 2px;\n        background-color: #ffffff;\n      }\n    }\n  "], ["\n    position: relative;\n    display: inline-block;\n    vertical-align: middle;\n    padding: 10px;\n    border-radius: 6px;\n    border: 2px solid #c1c1c1;\n    cursor: pointer;\n    margin-right: 12px;\n\n    input {\n      display: none;\n    }\n\n    &.checked {\n      background-color: ", ";\n      color: #ffffff;\n\n      &::before {\n        content: '';\n        position: absolute;\n        top: calc(50% + 3px);\n        left: calc(50% - 4px);\n        transform: translate(-50%, -50%) rotate(45deg);\n        width: 8px;\n        height: 2px;\n        background-color: #ffffff;\n      }\n\n      &::after {\n        content: '';\n        position: absolute;\n        top: 50%;\n        left: calc(50% + 3px);\n        transform: translate(-50%, -50%) rotate(-45deg);\n        width: 14px;\n        height: 2px;\n        background-color: #ffffff;\n      }\n    }\n  "])), submitBtnColor); },
};
var mode = getParam('mode');
export var FORM_DEFAULT_COLOR = '#8b5cf6';
var RequiredLabel = function () { return (_jsx("span", __assign({ className: 'font-normal', style: { color: 'red', fontSize: '14px' } }, { children: "(\u203B \u5FC5\u9808\u9805\u76EE)" }))); };
var AreaTitleAndDescription = function (_a) {
    var title = _a.title, description = _a.description, required = _a.required;
    return (_jsxs(_Fragment, { children: [_jsxs("span", __assign({ className: 'block font-bold my-2 pr-4' }, { children: [title, required && _jsx(RequiredLabel, {})] })), description && (_jsx("div", __assign({ className: 'break-all mb-2 text-sm' }, { children: lineBreakToParagraphAndHtmltoBrTag(description) })))] }));
};
var TextArea = function (_a) {
    var input = _a.input, register = _a.register, required = _a.required;
    return (_jsx("div", __assign({ className: 'mt-8' }, { children: _jsxs("label", __assign({ className: 'block' }, { children: [_jsx(AreaTitleAndDescription, { title: input.text, required: required, description: input.description }), _jsx("input", __assign({ type: 'text', className: 'appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight' }, register(input.uid, { required: required })))] })) })));
};
var MultipleLinesArea = function (_a) {
    var input = _a.input, register = _a.register, required = _a.required;
    return (_jsx("div", __assign({ className: 'mt-8' }, { children: _jsxs("label", __assign({ className: 'block' }, { children: [_jsx(AreaTitleAndDescription, { title: input.text, required: required, description: input.description }), _jsx("textarea", __assign({ className: 'appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight', rows: 3 }, register(input.uid, { required: required })))] })) })));
};
var EmailArea = function (_a) {
    var input = _a.input, register = _a.register, required = _a.required;
    return (_jsx("div", __assign({ className: 'mt-8' }, { children: _jsxs("label", __assign({ className: 'block' }, { children: [_jsx(AreaTitleAndDescription, { title: input.text, required: required, description: input.description }), _jsx("input", __assign({ type: 'email', className: 'appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight' }, register(input.uid, { required: required })))] })) })));
};
var DatetimeArea = function (_a) {
    var input = _a.input, register = _a.register, required = _a.required;
    var type = (function () {
        if (input.useDate && input.useTime) {
            return 'datetime-local';
        }
        else if (input.useDate) {
            return 'date';
        }
        else if (input.useTime) {
            return 'time';
        }
        else {
            return 'text';
        }
    })();
    return (_jsx("div", __assign({ className: 'mt-8' }, { children: _jsxs("label", __assign({ className: 'block' }, { children: [_jsx(AreaTitleAndDescription, { title: input.text, required: required, description: input.description }), _jsx("input", __assign({ type: type, className: 'appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight' }, register(input.uid, { required: required })))] })) })));
};
var RadioArea = function (_a) {
    var input = _a.input, register = _a.register, required = _a.required, pullDown = _a.pullDown;
    return (_jsxs("div", __assign({ className: 'mt-8' }, { children: [_jsx(AreaTitleAndDescription, { title: input.text, required: required, description: input.description }), _jsx("div", __assign({ className: 'mt-2' }, { children: pullDown ? (_jsxs("select", __assign({ className: 'border-2 border-gray-200 rounded text-gray-700', css: styles.selectArea }, register(input.uid, { required: required }), { children: [_jsx("option", __assign({ css: styles.selectOption, value: '' }, { children: "\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044" })), input.options.map(function (selectValue, index) { return (_jsx("option", __assign({ css: styles.selectOption, value: selectValue.label }, { children: selectValue.label }), index)); })] }))) : (input.options.map(function (radioSelect, index) { return (_jsxs("label", __assign({ className: 'block items-center mt-4', style: { marginRight: '2em' } }, { children: [_jsx("input", __assign({ type: 'radio', className: 'form-radio', value: radioSelect.label }, register(input.uid, { required: required }))), _jsx("span", __assign({ className: 'ml-2' }, { children: radioSelect.label }))] }), index)); })) }))] })));
};
var CheckboxArea = function (_a) {
    var input = _a.input, register = _a.register, required = _a.required, pullDown = _a.pullDown;
    return (_jsxs("div", __assign({ className: 'block mt-8' }, { children: [_jsx(AreaTitleAndDescription, { title: input.text, required: required, description: input.description }), _jsx("div", __assign({ className: 'mt-2' }, { children: _jsx("div", { children: pullDown ? (_jsx("select", __assign({ className: 'border-2 border-gray-200 rounded text-gray-700', css: styles.selectArea, multiple: true, size: input.options.length }, register(input.uid, { required: required }), { children: input.options.map(function (selectValue, index) { return (_jsx("option", __assign({ css: styles.selectOption, value: selectValue.label }, { children: selectValue.label }), index)); }) }))) : (input.options.map(function (checkSelect, index) { return (_jsxs("label", __assign({ className: 'block items-center mt-4' }, { children: [_jsx("input", __assign({ type: 'checkbox', className: 'form-checkbox text-indigo-600', value: checkSelect.label }, register(input.uid, { required: required }))), _jsx("span", __assign({ className: 'ml-2 inline-block', style: { marginRight: '2em' } }, { children: checkSelect.label }))] }), index)); })) }) }))] })));
};
var PrivacyPolicySection = function (_a) {
    var privacyPolicy = _a.privacyPolicy, isAgreePrivacyPolicy = _a.isAgreePrivacyPolicy, setIsAgreePrivacyPolicy = _a.setIsAgreePrivacyPolicy, submitBtnColor = _a.submitBtnColor;
    var isUrl = function () {
        /* eslint-disable no-useless-escape */
        return /^https?:\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#]+$/.test(privacyPolicy);
    };
    return (_jsx("div", __assign({ className: 'privacy-policy' }, { children: isUrl() ? (_jsxs("div", __assign({ css: styles.privacyPolicyWrapper }, { children: [_jsx("label", __assign({ css: styles.privacyPolicyCheckbox(submitBtnColor), className: isAgreePrivacyPolicy ? ' checked' : '' }, { children: _jsx("input", { type: 'checkbox', checked: isAgreePrivacyPolicy, onChange: function () { return setIsAgreePrivacyPolicy(!isAgreePrivacyPolicy); } }) })), _jsx("a", __assign({ href: privacyPolicy, target: '_blank', rel: 'noreferrer', css: styles.privacyPolicyLink }, { children: "\u30D7\u30E9\u30A4\u30D0\u30B7\u30FC\u30DD\u30EA\u30B7\u30FC" })), "\u306B\u540C\u610F\u3059\u308B"] }))) : (_jsxs("div", { children: [_jsxs("div", __assign({ css: styles.privacyPolicyWrapper, style: { marginBottom: '8px' } }, { children: [_jsx("label", __assign({ css: styles.privacyPolicyCheckbox(submitBtnColor), className: isAgreePrivacyPolicy ? 'checked' : '' }, { children: _jsx("input", { id: 'isAgreePrivacyPolicy', type: 'checkbox', checked: isAgreePrivacyPolicy, onChange: function () { return setIsAgreePrivacyPolicy(!isAgreePrivacyPolicy); } }) })), _jsx("label", __assign({ htmlFor: 'isAgreePrivacyPolicy' }, { children: _jsx("p", __assign({ className: 'block font-bold my-2 pr-4', style: { display: 'inline-block', verticalAlign: 'middle' } }, { children: "\u30D7\u30E9\u30A4\u30D0\u30B7\u30FC\u30DD\u30EA\u30B7\u30FC\u306B\u540C\u610F\u3059\u308B" })) }))] })), _jsx("textarea", { readOnly: true, name: 'privacypolicy', value: privacyPolicy, css: styles.privacyPolicyTextArea, rows: 8 })] })) })));
};
var FormBlock = function (_a) {
    var _b, _c;
    var uid = _a.uid, block = _a.block;
    var domain = window.location.hostname;
    var path = window.location.pathname;
    var _d = useState(false), isFormModalOpened = _d[0], setIsFormModalOpened = _d[1];
    var _e = useState(false), submitted = _e[0], setSubmitted = _e[1];
    var _f = useState(false), submitting = _f[0], setSubmitting = _f[1];
    var _g = useState(block.privacyPolicy ? false : true), isAgreePrivacyPolicy = _g[0], setIsAgreePrivacyPolicy = _g[1];
    var searchParams = new URL(window.location.href).searchParams;
    var inflowSource = searchParams.get('_src') || window.document.referrer || 'unknown';
    var _h = useForm({ mode: 'onChange' }), register = _h.register, handleSubmit = _h.handleSubmit, _j = _h.formState, errors = _j.errors, isValid = _j.isValid, isDirty = _j.isDirty;
    var onSubmit = function (data) {
        var fieldValues = block.fields.map(function (field) {
            if (field.type === 'checkbox') {
                // 1つしか選択肢がない場合、していない場合にreact formがstring or falseで返してくるので、全てstring[]に変換する
                var normalize = function (value) {
                    if (value === false) {
                        return [];
                    }
                    else if (Array.isArray(value)) {
                        return value;
                    }
                    else {
                        return [value];
                    }
                };
                var values = normalize(data[field.uid]);
                return {
                    fieldUid: field.uid,
                    value: undefined,
                    values: values,
                };
            }
            else {
                var value = data[field.uid];
                return {
                    fieldUid: field.uid,
                    value: value,
                    values: undefined,
                };
            }
        });
        setSubmitting(true);
        axios
            .post("".concat(API_END_POINT, "/public/sites/").concat(isCustomDomain(domain) ? "__custom_domain__" : uid, "/forms/").concat(block.uid), {
            fieldValues: fieldValues,
        }, {
            params: {
                domain: domain,
                path: path,
                inflowSource: encodeURIComponent(inflowSource),
            },
        })
            .then(function () {
            setIsFormModalOpened(true);
            setSubmitted(true);
            setSubmitting(false);
        })
            .catch(function (error) {
            console.error(error);
            window.alert('送信時にエラーが発生しました。ご迷惑おかけして申し訳ございません。お手数ですがもう１度お試しください。');
            setSubmitting(false);
        });
    };
    var hasError = function (uid) {
        var error = errors[uid];
        if (!error) {
            return false;
        }
        if (Array.isArray(error)) {
            return error.length > 0;
        }
        else {
            return error.message === '';
        }
    };
    var buildFormBlock = function (input) {
        switch (input.type) {
            case 'text':
                return (_jsxs("span", { children: [_jsx(TextArea, { register: register, required: input.required || false, input: input }), hasError(input.uid) && (_jsxs("span", __assign({ style: { color: 'red', fontSize: '14px' } }, { children: ["[", input.text, "]\u306F\u5165\u529B\u5FC5\u9808\u306E\u9805\u76EE\u306B\u306A\u308A\u307E\u3059"] })))] }, input.uid));
            case 'textarea':
                return (_jsxs("span", { children: [_jsx(MultipleLinesArea, { register: register, required: input.required || false, input: input }), hasError(input.uid) && (_jsxs("span", __assign({ style: { color: 'red', fontSize: '14px' } }, { children: ["[", input.text, "]\u306F\u5165\u529B\u5FC5\u9808\u306E\u9805\u76EE\u306B\u306A\u308A\u307E\u3059"] })))] }, input.uid));
            case 'email':
                return (_jsxs("span", { children: [_jsx(EmailArea, { register: register, required: input.required || false, input: input }), hasError(input.uid) && (_jsxs("span", __assign({ style: { color: 'red', fontSize: '14px' } }, { children: ["[", input.text, "]\u306F\u5165\u529B\u5FC5\u9808\u306E\u9805\u76EE\u306B\u306A\u308A\u307E\u3059"] })))] }, input.uid));
            case 'datetime':
                return (_jsxs("span", { children: [_jsx(DatetimeArea, { register: register, required: input.required || false, input: input }), hasError(input.uid) && (_jsxs("span", __assign({ style: { color: 'red', fontSize: '14px' } }, { children: ["[", input.text, "]\u306F\u5165\u529B\u5FC5\u9808\u306E\u9805\u76EE\u306B\u306A\u308A\u307E\u3059"] })))] }, input.uid));
            case 'radio':
                return (_jsxs("span", { children: [_jsx(RadioArea, { register: register, required: input.required || false, input: input, pullDown: input.pullDown }), hasError(input.uid) && (_jsxs("span", __assign({ style: { color: 'red', fontSize: '14px' } }, { children: ["[", input.text, "]\u306F\u5165\u529B\u5FC5\u9808\u306E\u9805\u76EE\u306B\u306A\u308A\u307E\u3059"] })))] }, input.uid));
            case 'checkbox':
                return (_jsxs("span", { children: [_jsx(CheckboxArea, { register: register, required: input.required || false, input: input, pullDown: input.pullDown }), hasError(input.uid) && (_jsxs("span", __assign({ style: { color: 'red', fontSize: '14px' } }, { children: ["[", input.text, "]\u306F\u5165\u529B\u5FC5\u9808\u306E\u9805\u76EE\u306B\u306A\u308A\u307E\u3059"] })))] }, input.uid));
            default:
                // eslint-disable-next-line no-case-declarations
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                var _exhaustiveCheck = input;
        }
    };
    var handleCloseFormModal = function () {
        setIsFormModalOpened(false);
    };
    return (_jsxs("section", __assign({ className: 'shadow-md my-8 w-11/12 m-auto text-left', style: { padding: '2em 1em', maxWidth: '800px' } }, { children: [_jsx("div", __assign({ className: 'text-center' }, { children: _jsx("h2", __assign({ className: 'my-4 text-2xl font-bold text-center', css: css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n            border-bottom: 3px solid\n              ", ";\n            display: inline-block;\n            margin: 0 auto 5px;\n            text-align: center;\n            padding-bottom: 0.3em;\n          "], ["\n            border-bottom: 3px solid\n              ", ";\n            display: inline-block;\n            margin: 0 auto 5px;\n            text-align: center;\n            padding-bottom: 0.3em;\n          "])), ((_b = block === null || block === void 0 ? void 0 : block.colors) === null || _b === void 0 ? void 0 : _b.enable) ? (_c = block === null || block === void 0 ? void 0 : block.colors) === null || _c === void 0 ? void 0 : _c.text : '#000000') }, { children: block.formName })) })), block.formDescription && (_jsx("p", { children: lineBreakToParagraphAndHtmltoBrTag(block.formDescription) })), _jsx("form", __assign({ className: 'w-full', onSubmit: handleSubmit(onSubmit) }, { children: _jsxs("div", __assign({ className: 'mb-6' }, { children: [block.fields.map(buildFormBlock), block.privacyPolicy && (_jsx(PrivacyPolicySection, { privacyPolicy: block.privacyPolicy, isAgreePrivacyPolicy: isAgreePrivacyPolicy, setIsAgreePrivacyPolicy: setIsAgreePrivacyPolicy, submitBtnColor: block.submitBtnColor ? block.submitBtnColor : FORM_DEFAULT_COLOR })), _jsxs("div", __assign({ className: 'flex mt-6 justify-center' }, { children: [_jsx("button", __assign({ css: submitted ||
                                        mode === 'preview' ||
                                        !isAgreePrivacyPolicy ||
                                        !isValid ||
                                        !isDirty
                                        ? css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n                      background-color: #777;\n                      cursor: default;\n                      padding: 1em 4em;\n                      font-size: 20px;\n                      opacity: 0.5;\n                      width: 260px;\n                    "], ["\n                      background-color: #777;\n                      cursor: default;\n                      padding: 1em 4em;\n                      font-size: 20px;\n                      opacity: 0.5;\n                      width: 260px;\n                    "]))) : css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n                      background-color: ", ";\n                      padding: 1em 4em;\n                      font-size: 20px;\n                      width: 240px;\n                    "], ["\n                      background-color: ", ";\n                      padding: 1em 4em;\n                      font-size: 20px;\n                      width: 240px;\n                    "])), block.submitBtnColor
                                        ? block.submitBtnColor
                                        : FORM_DEFAULT_COLOR), disabled: submitted ||
                                        submitting ||
                                        mode === 'preview' ||
                                        !isAgreePrivacyPolicy ||
                                        !isDirty ||
                                        !isValid, className: submitted
                                        ? 'rounded-md px-12 shadow text-white font-bold bg-none'
                                        : 'rounded-md px-12 shadow text-white font-bold', type: 'submit' }, { children: submitting ? (_jsx(ReactLoading, { height: '30px', width: '20px', type: 'spin', css: css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n                    margin: 0 auto;\n                    height: 30px;\n                    width: 20px;\n                  "], ["\n                    margin: 0 auto;\n                    height: 30px;\n                    width: 20px;\n                  "]))) })) : submitted ? ('送信済です') : ('送信する') })), _jsx(FormModal, { id: "thanks_".concat(block.uid), isOpen: isFormModalOpened, onRequestClose: handleCloseFormModal, block: block })] }))] })) }))] }), block.uid));
};
export default FormBlock;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
