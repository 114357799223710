var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import React from 'react';
import { getSupportedImageMeta } from '../utils/image-fallback';
/**
 * 画像タグ、以下をサポートします。
 * - WebPサポートのフォールバック
 * - 画像ロード前の適切な高さの設定(CLS対策)
 * @param props
 * @returns
 */
var Img = function (props) {
    var image = getSupportedImageMeta(props.src || '');
    if (!image.width || !image.height) {
        return _jsx("img", __assign({}, Object.assign(__assign({}, props), { src: image.url })));
    }
    var newProps = Object.assign(__assign({}, props), { src: image.url, width: image.width, height: image.height });
    return _jsx("img", __assign({}, newProps));
};
export default Img;
