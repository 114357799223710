var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import React from 'react';
import { lineBreakToParagraphAndHtml } from '../utils/browserUtils';
var HeadingAndDescription = function (_a) {
    var block = _a.block;
    return (_jsxs("div", __assign({ style: { textAlign: block.textAlign } }, { children: [block.title && (_jsx("h2", __assign({ className: 'my-4 text-2xl font-bold' }, { children: lineBreakToParagraphAndHtml(block.title) }))), block.text && (_jsx("div", __assign({ className: 'mt-4 text-base' }, { children: lineBreakToParagraphAndHtml(block.text) })))] })));
};
export default HeadingAndDescription;
