var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import React from 'react';
import { lineBreakToParagraphAndHtml } from '../utils/browserUtils';
import Img from './Img';
var style = {
    areaWrapper: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    @media (max-width: 600px) {\n      margin-bottom: 3em;\n    }\n  "], ["\n    display: flex;\n    align-items: center;\n    @media (max-width: 600px) {\n      margin-bottom: 3em;\n    }\n  "]))),
    area: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    box-sizing: border-box;\n    width: 50%;\n    padding: 0.5em;\n    position: relative;\n    text-align: left;\n    img {\n      width: 100%;\n    }\n    @media (max-width: 600px) {\n      width: 100%;\n      margin-bottom: 1em;\n    }\n  "], ["\n    box-sizing: border-box;\n    width: 50%;\n    padding: 0.5em;\n    position: relative;\n    text-align: left;\n    img {\n      width: 100%;\n    }\n    @media (max-width: 600px) {\n      width: 100%;\n      margin-bottom: 1em;\n    }\n  "]))),
    title: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    font-size: 40px;\n    font-weight: bold;\n    line-height: 1.2;\n    margin-bottom: 0.5em;\n    @media (max-width: 600px) {\n      font-size: 30px;\n    }\n  "], ["\n    font-size: 40px;\n    font-weight: bold;\n    line-height: 1.2;\n    margin-bottom: 0.5em;\n    @media (max-width: 600px) {\n      font-size: 30px;\n    }\n  "]))),
    text: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    font-size: 16px;\n  "], ["\n    font-size: 16px;\n  "]))),
    indexNumber: css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    position: absolute;\n    top: -0.5em;\n    left: -0.2em;\n    font-size: 150px;\n    font-weight: bold;\n    mix-blend-mode: overlay;\n    @media (max-width: 600px) {\n      top: -0.6em;\n      left: 0;\n    }\n  "], ["\n    position: absolute;\n    top: -0.5em;\n    left: -0.2em;\n    font-size: 150px;\n    font-weight: bold;\n    mix-blend-mode: overlay;\n    @media (max-width: 600px) {\n      top: -0.6em;\n      left: 0;\n    }\n  "]))),
};
var TitleAndText = function (_a) {
    var image = _a.image;
    if (image.title || image.text) {
        return (_jsxs("div", __assign({ css: style.area }, { children: [image.title && (_jsx("div", __assign({ css: style.title }, { children: lineBreakToParagraphAndHtml(image.title) }))), image.text && (_jsx("div", __assign({ css: style.text }, { children: lineBreakToParagraphAndHtml(image.text) })))] })));
    }
    return null;
};
var Image = function (_a) {
    var block = _a.block;
    var images = block.images;
    return (_jsx(_Fragment, { children: images.map(function (image, index) { return (_jsxs("div", __assign({ css: [
                style.areaWrapper,
                css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n              flex-direction: ", ";\n              @media (max-width: 600px) {\n                flex-direction: ", ";\n              }\n            "], ["\n              flex-direction: ", ";\n              @media (max-width: 600px) {\n                flex-direction: ", ";\n              }\n            "])), image.imageAreaAlign === 'right'
                    ? 'row-reverse'
                    : 'row', image.imageAreaAlignForSP === 'top'
                    ? 'column-reverse'
                    : 'column'),
            ] }, { children: [_jsx("div", __assign({ css: [
                        style.area,
                        !image.title && !image.text
                            ? css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n                    width: 100%;\n                  "], ["\n                    width: 100%;\n                  "]))) : css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n                    width: 50%;\n                  "], ["\n                    width: 50%;\n                  "]))),
                    ] }, { children: _jsxs("p", { children: [block.numberFrag && _jsx("p", __assign({ css: style.indexNumber }, { children: index + 1 })), _jsx(Img, { src: image.imagePath || '', alt: '', loading: 'lazy', css: image.imageSize
                                    ? {
                                        width: "".concat(image.imageSize, " !important"),
                                        margin: '0 auto',
                                    }
                                    : {} })] }) })), _jsx(TitleAndText, { image: image })] }), index)); }) }));
};
export default Image;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
