var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Grid } from '@material-ui/core';
import React from 'react';
import EmbeddedYouTube from '../EmbeddedYouTube';
import './MultiYouTube.scss';
var MultiYouTube = function (_a) {
    var block = _a.block;
    return (_jsx(Grid, __assign({ container: true }, { children: block.youtubeList.map(function (youtube, index) {
            return (_jsx("div", __assign({ className: "column_".concat(block.columns) }, { children: _jsx(EmbeddedYouTube, { block: {
                        type: 'youtube',
                        html: youtube.html,
                        uid: youtube.uid,
                        title: youtube.title,
                        titleTextAlign: youtube.titleTextAlign,
                        description: youtube.description,
                        descriptionTextAlign: youtube.descriptionTextAlign,
                    } }, index) }), index));
        }) })));
};
export default MultiYouTube;
