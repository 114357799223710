var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import React, { useCallback } from 'react';
import useTrackClick from '../api/use-track-click';
import { replaceUrl } from '../utils/url';
import LinkButton from './LinkButton';
var LinkCollection = function (_a) {
    var block = _a.block, color = _a.color, icons = _a.icons, setting = _a.setting;
    var siteUid = window.location.pathname.substring(1);
    var domain = window.location.hostname;
    var path = window.location.pathname;
    var trackClick = useTrackClick().trackClick;
    var searchParams = new URL(window.location.href).searchParams;
    var inflowSource = searchParams.get('_src') || window.document.referrer;
    var handleClick = useCallback(function (event, url, trackingUrl) {
        event.preventDefault();
        trackClick(siteUid, trackingUrl || url, domain, path, inflowSource).finally(function () {
            if (window == window.parent) {
                window.location.href = url;
            }
            else {
                window.open(url, '_blank');
            }
        });
    }, []);
    var linkCount = block.links.length;
    return (_jsx("div", __assign({ css: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        color: ", ";\n      "], ["\n        color: ", ";\n      "])), color) }, { children: _jsx("div", __assign({ className: block.links.length === 1
                ? ''
                : 'grid grid-cols-1 lg:grid-cols-2 gap-5' }, { children: block.links.map(function (link, index) {
                var _a, _b;
                return (_jsx(LinkButton, { linkCount: linkCount, imageUrl: undefined, url: replaceUrl(link.url, inflowSource), onClick: function (event) {
                        return handleClick(event, replaceUrl(link.url, inflowSource), link.url);
                    }, text: link.text, description: link.description, icon: link.icon, icons: icons, color: ((_a = block === null || block === void 0 ? void 0 : block.colors) === null || _a === void 0 ? void 0 : _a.enable) ? (_b = block === null || block === void 0 ? void 0 : block.colors) === null || _b === void 0 ? void 0 : _b.text : setting.themeColor }, index));
            }) })) })));
};
export default LinkCollection;
var templateObject_1;
