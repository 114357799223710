var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import React, { useMemo } from 'react';
import Img from '../../../components/Img';
import { lineBreakToParagraphAndHtml as LB2PAndH } from '../../../utils/browserUtils';
import { scrollMessageSenderStyle, createScrollMessageSender, } from '../../../utils/scroll-message-sender';
var styles = {
    wrapper: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: absolute;\n    top: 15%;\n    left: 5%;\n    width: 45%;\n    background-color: rgba(255, 255, 255, 0.55);\n    text-align: left;\n    border-radius: 10px;\n\n    @media (max-width: 768px) {\n      position: static;\n      width: 100%;\n      background: none;\n    }\n  "], ["\n    position: absolute;\n    top: 15%;\n    left: 5%;\n    width: 45%;\n    background-color: rgba(255, 255, 255, 0.55);\n    text-align: left;\n    border-radius: 10px;\n\n    @media (max-width: 768px) {\n      position: static;\n      width: 100%;\n      background: none;\n    }\n  "]))),
    mainText: function (subText) {
        var marginTop = subText ? 0 : '24px';
        return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      font-size: 4.5vw;\n      font-weight: bold;\n      margin: ", " 22px 24px 22px;\n      text-align: left;\n      line-height: 1.2;\n      @media (max-width: 768px) {\n        font-size: 36px;\n      }\n    "], ["\n      font-size: 4.5vw;\n      font-weight: bold;\n      margin: ", " 22px 24px 22px;\n      text-align: left;\n      line-height: 1.2;\n      @media (max-width: 768px) {\n        font-size: 36px;\n      }\n    "])), marginTop);
    },
    subText: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    font-size: 20px;\n    font-weight: bold;\n    margin: 24px 22px;\n    text-align: left;\n  "], ["\n    font-size: 20px;\n    font-weight: bold;\n    margin: 24px 22px;\n    text-align: left;\n  "]))),
    linkButton: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    margin: 24px 22px;\n    @media (max-width: 768px) {\n      text-align: center;\n    }\n  "], ["\n    margin: 24px 22px;\n    @media (max-width: 768px) {\n      text-align: center;\n    }\n  "]))),
    linkButton_button: function (color) {
        return css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      background-color: ", ";\n      border-radius: 10px;\n      display: inline-block;\n      padding: 0.5em 2em;\n      position: relative;\n      &::after {\n        content: '';\n        width: 10px;\n        height: 10px;\n        display: block;\n        position: absolute;\n        top: 50%;\n        right: 12px;\n        margin-top: -6px;\n        transform: rotate(45deg);\n        transition: right 0.3s;\n      }\n      &:hover {\n        &::after {\n          right: 7px;\n        }\n      }\n      &:active,\n      &:focus {\n        opacity: 0.8;\n      }\n    "], ["\n      background-color: ", ";\n      border-radius: 10px;\n      display: inline-block;\n      padding: 0.5em 2em;\n      position: relative;\n      &::after {\n        content: '';\n        width: 10px;\n        height: 10px;\n        display: block;\n        position: absolute;\n        top: 50%;\n        right: 12px;\n        margin-top: -6px;\n        transform: rotate(45deg);\n        transition: right 0.3s;\n      }\n      &:hover {\n        &::after {\n          right: 7px;\n        }\n      }\n      &:active,\n      &:focus {\n        opacity: 0.8;\n      }\n    "])), color);
    },
    linkButton_text: function (description) {
        var paddingBottom = description ? '0' : '15px';
        return css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      font-weight: bold;\n      padding: 15px 0 ", " 0;\n    "], ["\n      font-weight: bold;\n      padding: 15px 0 ", " 0;\n    "])), paddingBottom);
    },
    linkButton_description: css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    font-size: 14px;\n    margin: 10px 0 0 0;\n    padding: 0 0 15px 0;\n  "], ["\n    font-size: 14px;\n    margin: 10px 0 0 0;\n    padding: 0 0 15px 0;\n  "]))),
    image: css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    margin: 0 22px;\n    max-width: 640px;\n    width: auto;\n    @media screen and (max-width: 768px) {\n      margin: 0 auto;\n      padding: 0 10px;\n    }\n  "], ["\n    margin: 0 22px;\n    max-width: 640px;\n    width: auto;\n    @media screen and (max-width: 768px) {\n      margin: 0 auto;\n      padding: 0 10px;\n    }\n  "]))),
};
// キャッチサブテキスト
var CatchSubTextBlock = function (_a) {
    var subText = _a.subText;
    if (subText) {
        return _jsx("div", __assign({ css: styles.subText }, { children: LB2PAndH(subText) }));
    }
    else
        return null;
};
// キャッチメインテキスト
var CatchMainTextBlock = function (_a) {
    var mainText = _a.mainText, subText = _a.subText;
    if (mainText) {
        return _jsx("div", __assign({ css: styles.mainText(subText) }, { children: LB2PAndH(mainText) }));
    }
    else
        return null;
};
// リンクボタン
var LinkButtonBlock = function (_a) {
    var conversionColor = _a.conversionColor, url = _a.url, text = _a.text, description = _a.description, border = _a.border;
    var textAndAllowStyle = conversionColor === '#ffffff' || conversionColor === 'transparent'
        ? '#000000'
        : '#ffffff';
    var borderStyle = css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    border: ", ";\n    &::after {\n      border-top: 2px solid #fff;\n      border-right: 2px solid #fff;\n    }\n    @media (max-width: 768px) {\n      border: ", ";\n      &::after {\n        border-top: 2px solid ", ";\n        border-right: 2px solid ", ";\n      }\n    }\n  "], ["\n    border: ", ";\n    &::after {\n      border-top: 2px solid #fff;\n      border-right: 2px solid #fff;\n    }\n    @media (max-width: 768px) {\n      border: ", ";\n      &::after {\n        border-top: 2px solid ", ";\n        border-right: 2px solid ", ";\n      }\n    }\n  "])), border ? '2px solid white' : 'none', border ? '2px solid black' : 'none', textAndAllowStyle, textAndAllowStyle);
    var textColor = css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    color: #fff;\n    @media (max-width: 768px) {\n      color: ", ";\n    }\n  "], ["\n    color: #fff;\n    @media (max-width: 768px) {\n      color: ", ";\n    }\n  "])), textAndAllowStyle);
    if (text || description) {
        // リンクボタンテキスト
        var _linkButtonText = function (text) {
            if (text) {
                return (_jsx("div", __assign({ css: [styles.linkButton_text(description), textColor] }, { children: text })));
            }
            else
                return null;
        };
        // リンクボタン説明
        var _linkButtonDescription = function (description) {
            if (description) {
                return (_jsx("div", __assign({ css: [styles.linkButton_description, textColor] }, { children: description })));
            }
            else
                return null;
        };
        return (_jsx("div", __assign({ css: styles.linkButton }, { children: _jsxs("a", __assign({ css: [styles.linkButton_button(conversionColor), borderStyle], href: url }, { children: [_linkButtonText(text), _linkButtonDescription(description)] })) })));
    }
    else
        return null;
};
// キャッチブロック
export var CatchBlock = function (_a) {
    var catchphrase = _a.catchphrase;
    if (catchphrase) {
        var mainText = catchphrase.mainText, subText = catchphrase.subText, conversionButton = catchphrase.conversionButton, bgColor_1 = catchphrase.bgColor, textColor_1 = catchphrase.textColor, bgOpactity_1 = catchphrase.bgOpactity, imagePath = catchphrase.imagePath;
        var text = conversionButton.text, description = conversionButton.description, url = conversionButton.url, conversionColor = conversionButton.conversionColor;
        var backgroundStyles = useMemo(function () {
            var red = parseInt(bgColor_1.substring(1, 3), 16);
            var green = parseInt(bgColor_1.substring(3, 5), 16);
            var blue = parseInt(bgColor_1.substring(5, 7), 16);
            var alpha = bgOpactity_1;
            return css(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n        background-color: rgba(", ", ", ", ", ", ", ");\n        color: ", ";\n        @media screen and (max-width: 768px) {\n          color: #000 !important;\n        }\n      "], ["\n        background-color: rgba(", ", ", ", ", ", ", ");\n        color: ", ";\n        @media screen and (max-width: 768px) {\n          color: #000 !important;\n        }\n      "])), red, green, blue, alpha, textColor_1);
        }, [bgColor_1, bgOpactity_1]);
        return (_jsxs("div", __assign({ css: [styles.wrapper, backgroundStyles, scrollMessageSenderStyle()], onClick: createScrollMessageSender('catchphrase') }, { children: [_jsx(CatchSubTextBlock, { subText: subText }), _jsx(CatchMainTextBlock, { mainText: mainText, subText: subText }), _jsx("p", __assign({ css: styles.image }, { children: _jsx(Img, { src: imagePath }) })), _jsx(LinkButtonBlock, { conversionColor: conversionColor, url: url, text: text, description: description, border: conversionButton.border })] })));
    }
    else
        return null;
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
