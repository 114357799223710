var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import React from 'react';
import { cloneHtmlCollection } from '../utils/browserUtils';
var alignJustifyValues = {
    center: 'justify-center',
    left: 'justify-start',
    right: 'justify-end',
};
var widthStyles = {
    full: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin: 0 calc(50% - 50cqi);\n    width: 100vw;\n    text-align: start;\n  "], ["\n    margin: 0 calc(50% - 50cqi);\n    width: 100vw;\n    text-align: start;\n  "]))),
    content: null,
};
var Html = function (_a) {
    var block = _a.block;
    var htmlString = block.html;
    var containerRef = React.useRef(null);
    React.useEffect(function () {
        if (containerRef.current) {
            // containerRef.currentの子要素をすべて削除
            while (containerRef.current.firstChild) {
                containerRef.current.removeChild(containerRef.current.firstChild);
            }
            // scriptタグが実行されるように
            // innerHTMLではなく一旦div要素に入れ、
            // cloneHtmlCollectionでコピーした新規要素をappendChildする
            var parent = document.createElement('div');
            parent.innerHTML = htmlString;
            for (var _i = 0, _a = cloneHtmlCollection(parent.children); _i < _a.length; _i++) {
                var node = _a[_i];
                containerRef.current.appendChild(node);
            }
        }
    }, [htmlString]);
    return (_jsx("section", { className: "w-full my-4 m-auto flex ".concat(alignJustifyValues[block.align]), css: widthStyles[block.width], ref: containerRef }, block.uid));
};
export default Html;
var templateObject_1;
