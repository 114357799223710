var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import React, { useEffect, useRef, useState } from 'react';
import { Rating } from 'react-simple-star-rating';
import { lineBreakToParagraphAndHtmltoBrTag } from '../utils/browserUtils';
var Review = function (_a) {
    var _b, _c;
    var shopReview = _a.shopReview, block = _a.block;
    var _d = useState(false), readMore = _d[0], setReadMore = _d[1];
    var ref = useRef(null);
    useEffect(function () {
        function handleResize() {
            if (ref.current) {
                if (ref.current.clientHeight < ref.current.scrollHeight) {
                    setReadMore(false);
                }
                else {
                    setReadMore(true);
                }
            }
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return function () { return window.removeEventListener('resize', handleResize); };
    }, []);
    return (_jsx("div", __assign({ className: 'review_block' }, { children: _jsxs("dl", __assign({ className: 'text-left border-t-2 border-solid border-gray-200 py-4 my-4' }, { children: [_jsxs("dt", __assign({ className: 'my-2' }, { children: [_jsx(Rating, { ratingValue: shopReview.rate * 20, size: 30, allowHalfIcon: true, allowHover: false, style: { cursor: 'default' }, onClick: function () { return null; }, readonly: true }), _jsx("span", __assign({ className: 'pl-2 align-middle', css: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n              ", "\n            "], ["\n              ", "\n            "])), ((_b = block === null || block === void 0 ? void 0 : block.colors) === null || _b === void 0 ? void 0 : _b.enable) ? (_c = block === null || block === void 0 ? void 0 : block.colors) === null || _c === void 0 ? void 0 : _c.text : '#505050') }, { children: shopReview.relativeTime }))] })), _jsx("dd", __assign({ style: { lineHeight: '1.5' }, ref: ref, className: "".concat(!readMore && 'line-clamp-3') }, { children: _jsx("p", { children: lineBreakToParagraphAndHtmltoBrTag(shopReview.comment) }) })), readMore === false && (_jsx("button", __assign({ css: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n              background: none;\n            "], ["\n              background: none;\n            "]))), onClick: function () { return setReadMore(true); }, className: 'bg-current mt-2  underline' }, { children: "\u7D9A\u304D\u3092\u8AAD\u3080" })))] }), shopReview.reviewId) })));
};
export default Review;
var templateObject_1, templateObject_2;
