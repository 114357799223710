var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import React, { useCallback } from 'react';
import useTrackClick from '../api/use-track-click';
import { replaceUrl } from '../utils/url';
var Conversion = function (_a) {
    var block = _a.block;
    var siteUid = window.location.pathname.substring(1);
    var domain = window.location.hostname;
    var path = window.location.pathname;
    var trackClick = useTrackClick().trackClick;
    var searchParams = new URL(window.location.href).searchParams;
    var inflowSource = searchParams.get('_src') || window.document.referrer;
    var handleClick = useCallback(function (event, url, trackingUrl) {
        event.preventDefault();
        trackClick(siteUid, trackingUrl || url, domain, path, inflowSource).finally(function () {
            if (window == window.parent) {
                window.location.href = url;
            }
            else {
                window.open(url, '_blank');
            }
        });
    }, []);
    var replacedUrl = replaceUrl(block.url, inflowSource);
    return (_jsx(_Fragment, { children: _jsx("a", __assign({ className: 'inline-block', href: replacedUrl, onClick: function (event) { return handleClick(event, replacedUrl, block.url); } }, { children: _jsxs("button", __assign({ style: {
                    backgroundColor: block.conversionColor,
                    // minWidth: '375px',
                    minWidth: '357.5px',
                }, className: 'hover:opacity-80 shadow-md px-8 my-2 h-24 text-white rounded-lg' }, { children: [block.text && _jsx("p", __assign({ className: 'text-2xl font-bold' }, { children: block.text })), block.description && _jsx("p", __assign({ className: 'text-sm' }, { children: block.description }))] })) })) }));
};
export default Conversion;
