import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import React from 'react';
import sanitizeHtml from 'sanitize-html';
export var lineBreakToParagraph = function (str) {
    return str.split('\n').map(function (line) { return _jsx("p", { children: line }); });
};
export var sanitize = function (str) {
    return sanitizeHtml(str, {
        allowedTags: ['img', 'span', 'div'],
        allowedAttributes: {
            img: ['src', 'width', 'height', 'style'],
            span: ['style'],
            div: ['style'],
        },
        parseStyleAttributes: false,
    });
};
export var lineBreakToParagraphAndHtml = function (str) {
    return str
        .split('\n')
        .map(function (line, index) { return (_jsx("p", { dangerouslySetInnerHTML: { __html: sanitize(line) } }, index)); });
};
export var copyToClipborad = function (text) {
    var tmp = document.createElement('div');
    var pre = document.createElement('pre');
    pre.style.webkitUserSelect = 'auto';
    pre.style.userSelect = 'auto';
    tmp.appendChild(pre).textContent = text;
    var s = tmp.style;
    s.position = 'fixed';
    s.right = '200%';
    document.body.appendChild(tmp);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    document.getSelection().selectAllChildren(tmp);
    document.execCommand('copy');
    document.body.removeChild(tmp);
};
export var cloneElement = function (srcElem) {
    var newElem = document.createElement(srcElem.tagName);
    for (var _i = 0, _a = Array.from(srcElem.attributes); _i < _a.length; _i++) {
        var attr = _a[_i];
        newElem.setAttribute(attr.name, attr.value);
    }
    newElem.innerHTML = srcElem.innerHTML;
    return newElem;
};
export var cloneElements = function (srcElems) {
    return Array.from(srcElems).map(cloneElement);
};
export var cloneHtmlCollection = function (srcElems) {
    return cloneElements(Array.from(srcElems));
};
export var insertUserScripts = function (userScript, position) {
    try {
        // 以前のユーザースクリプトを削除
        var oldUserScripts = document.querySelectorAll("".concat(position === 'head_last' ? 'head' : 'body', " [data-user-script]"));
        oldUserScripts.forEach(function (elem) {
            elem.remove();
        });
        // 新規にユーザースクリプトを挿入
        var parent = document.createElement('div');
        parent.innerHTML = userScript;
        var newElems = cloneHtmlCollection(parent.children).map(function (elem) {
            elem.setAttribute('data-user-script', 'true');
            return elem;
        });
        for (var _i = 0, newElems_1 = newElems; _i < newElems_1.length; _i++) {
            var elem = newElems_1[_i];
            if (position == 'head_last') {
                document.head.appendChild(elem);
            }
            else if (position == 'body_first') {
                document.body.prepend(elem);
            }
        }
    }
    catch (e) {
        console.error("Can't insert user scripts", e, userScript);
    }
};
export var lineBreakToParagraphAndHtmltoBrTag = function (str) {
    var lineLength = str.split('\n').length;
    return str.split('\n').map(function (line, index) { return (_jsxs(React.Fragment, { children: [line && line, lineLength !== index + 1 && _jsx("br", {})] }, index)); });
};
