var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import useTrackClick from '../api/use-track-click';
import { getParam } from '../utils/getParam';
import { isMobile } from '../utils/isMobile';
import { scrollMessageSenderStyle, createScrollMessageSender, } from '../utils/scroll-message-sender';
import { replaceUrl } from '../utils/url';
var mode = getParam('mode');
var isMobileFrag = isMobile();
var NavigationMenu = function (_a) {
    var navigationMenu = _a.navigationMenu;
    var siteUid = window.location.pathname.substring(1);
    var domain = window.location.hostname;
    var path = window.location.pathname;
    var trackClick = useTrackClick().trackClick;
    var searchParams = new URL(window.location.href).searchParams;
    var inflowSource = searchParams.get('_src') || window.document.referrer;
    var _b = React.useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var handleClickOpenToggleMenu = function () {
        setIsOpen(function (prev) { return !prev; });
    };
    var handleClickFactory = function (url, trackingUrl) { return function (event) {
        event.preventDefault();
        if (url == null) {
            return;
        }
        trackClick(siteUid, trackingUrl, domain, path, inflowSource).finally(function () {
            if (window == window.parent) {
                window.location.href = url;
            }
            else {
                window.open(url, '_blank');
            }
        });
    }; };
    var style = {
        navWrapper: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      position: fixed;\n      right: -3px;\n      top: 0;\n      transform: ", ";\n      transition: transform 0.6s cubic-bezier(0.16, 1, 0.3, 1);\n      z-index: 10;\n    "], ["\n      position: fixed;\n      right: -3px;\n      top: 0;\n      transform: ", ";\n      transition: transform 0.6s cubic-bezier(0.16, 1, 0.3, 1);\n      z-index: 10;\n    "])), isOpen ? 'translateX(0)' : 'translateX(100%)'),
        list: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      box-shadow: -2px 2px 3px 0px rgba(0, 0, 0, 0.4);\n      width: 300px !important;\n      flex-direction: column !important;\n      display: flex !important;\n      text-align: center !important;\n      background-color: white !important;\n      max-height: 100vh !important;\n      overflow-y: auto !important;\n      > a {\n        flex-grow: 1 !important;\n        padding: 16px 32px !important;\n        box-sizing: border-box !important;\n        min-width: 50% !important;\n        color: #555 !important;\n        font-weight: 700 !important;\n        border-bottom: 1px solid #e1e1e1 !important;\n        &[href^='tel:'] {\n          display: inline-block;\n          pointer-events: ", ";\n        }\n      }\n    "], ["\n      box-shadow: -2px 2px 3px 0px rgba(0, 0, 0, 0.4);\n      width: 300px !important;\n      flex-direction: column !important;\n      display: flex !important;\n      text-align: center !important;\n      background-color: white !important;\n      max-height: 100vh !important;\n      overflow-y: auto !important;\n      > a {\n        flex-grow: 1 !important;\n        padding: 16px 32px !important;\n        box-sizing: border-box !important;\n        min-width: 50% !important;\n        color: #555 !important;\n        font-weight: 700 !important;\n        border-bottom: 1px solid #e1e1e1 !important;\n        &[href^='tel:'] {\n          display: inline-block;\n          pointer-events: ", ";\n        }\n      }\n    "])), !isMobileFrag && mode !== 'preview'
            ? 'none'
            : 'auto'),
        icon: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      cursor: pointer;\n      padding: 8px;\n      position: absolute;\n      left: -4em;\n      background: white;\n      svg {\n        font-size: xxx-large !important;\n      }\n    "], ["\n      cursor: pointer;\n      padding: 8px;\n      position: absolute;\n      left: -4em;\n      background: white;\n      svg {\n        font-size: xxx-large !important;\n      }\n    "]))),
    };
    var buildInternalMenuItem = function (item, index) {
        return (_jsx("a", __assign({ onClick: handleClickOpenToggleMenu, href: "#".concat(item.blockUid) }, { children: item.text }), index));
    };
    var buildExternalMenuItem = function (item, index) {
        return (_jsx("a", __assign({ css: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n          background: none;\n          color: #555;\n        "], ["\n          background: none;\n          color: #555;\n        "]))), href: item.url != null ? replaceUrl(item.url, inflowSource) : '', target: '_blank', rel: 'noreferrer', onClick: handleClickFactory(replaceUrl(item.url, inflowSource), item.url) }, { children: item.text }), index));
    };
    var buildMenuItem = function (item, index) {
        if (item.type === 'internal') {
            return buildInternalMenuItem(item, index);
        }
        else if (item.type === 'external') {
            return buildExternalMenuItem(item, index);
        }
        else {
            return null;
        }
    };
    return (_jsxs("div", __assign({ css: style.navWrapper }, { children: [_jsx("button", __assign({ css: style.icon, onClick: handleClickOpenToggleMenu }, { children: isOpen ? _jsx(CloseIcon, {}) : _jsx(MenuIcon, {}) })), _jsx("nav", __assign({ css: [style.list, scrollMessageSenderStyle()], onClick: createScrollMessageSender('navigation-menu'), className: 'navigationMenu_block' }, { children: navigationMenu.items.map(buildMenuItem) }))] })));
};
export default NavigationMenu;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
