var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import React from 'react';
import { lineBreakToParagraphAndHtmltoBrTag } from '../utils/browserUtils';
var styles = {
    qa: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    text-align: left;\n    margin: 2.5em 0;\n    font-size: 20px;\n    align-items: flex-start;\n    @media (max-width: 600px) {\n      font-size: 16px;\n    }\n  "], ["\n    text-align: left;\n    margin: 2.5em 0;\n    font-size: 20px;\n    align-items: flex-start;\n    @media (max-width: 600px) {\n      font-size: 16px;\n    }\n  "]))),
    question: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin: 1em 0;\n    display: flex;\n    font-weight: bold;\n  "], ["\n    margin: 1em 0;\n    display: flex;\n    font-weight: bold;\n  "]))),
    answer: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    margin: 1em 0;\n    display: flex;\n  "], ["\n    margin: 1em 0;\n    display: flex;\n  "]))),
    icon: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    font-size: 64px;\n    margin-right: 0.3em;\n    max-width: none;\n    font-weight: bold;\n    @media (max-width: 600px) {\n      font-size: 48px;\n    }\n  "], ["\n    font-size: 64px;\n    margin-right: 0.3em;\n    max-width: none;\n    font-weight: bold;\n    @media (max-width: 600px) {\n      font-size: 48px;\n    }\n  "]))),
    answer_icon: css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    color: #1a9fa1;\n  "], ["\n    color: #1a9fa1;\n  "]))),
    text: css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    margin-top: 1.7em;\n  "], ["\n    margin-top: 1.7em;\n  "]))),
};
var QandA = function (_a) {
    var block = _a.block;
    return (_jsx(_Fragment, { children: block.qaList.map(function (qa, index) {
            return (qa.question || qa.answer) && (_jsxs("div", __assign({ css: styles.qa }, { children: [_jsxs("p", __assign({ css: styles.question }, { children: [_jsx("span", __assign({ css: styles.icon, className: 'icon_question' }, { children: "Q" })), _jsx("span", __assign({ css: styles.text }, { children: lineBreakToParagraphAndHtmltoBrTag(qa.question) }))] })), _jsxs("p", __assign({ css: styles.answer }, { children: [_jsx("span", __assign({ css: [styles.answer_icon, styles.icon], className: 'icon_answer' }, { children: "A" })), _jsx("span", __assign({ css: styles.text }, { children: lineBreakToParagraphAndHtmltoBrTag(qa.answer) }))] }))] }), index));
        }) }));
};
export default QandA;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
