var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import { Button, TextField } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import React, { useState } from 'react';
var styles = {
    wrapper: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    height: 100vh;\n    justify-content: center;\n    align-items: center;\n  "], ["\n    display: flex;\n    height: 100vh;\n    justify-content: center;\n    align-items: center;\n  "]))),
    paper: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin: 2em auto;\n    padding: 2em;\n    max-width: 600px;\n  "], ["\n    margin: 2em auto;\n    padding: 2em;\n    max-width: 600px;\n  "]))),
    img: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    height: 100px;\n    margin: 1em auto;\n  "], ["\n    height: 100px;\n    margin: 1em auto;\n  "]))),
    title: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    margin: 1em auto;\n    text-align: center;\n    font-size: 1.5em;\n  "], ["\n    margin: 1em auto;\n    text-align: center;\n    font-size: 1.5em;\n  "]))),
    passwordIncorrect: css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    margin: 1em auto;\n    font-size: 1em;\n    text-align: center;\n    color: red;\n  "], ["\n    margin: 1em auto;\n    font-size: 1em;\n    text-align: center;\n    color: red;\n  "]))),
    form: css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    text-align: center;\n    margin: 1em auto;\n  "], ["\n    text-align: center;\n    margin: 1em auto;\n  "]))),
};
export var SiteAccessPassword = function (_a) {
    var onSubmit = _a.onSubmit, isPasswordIncorrect = _a.isPasswordIncorrect;
    var _b = useState(''), password = _b[0], setPassword = _b[1];
    return (_jsx("div", __assign({ css: styles.wrapper }, { children: _jsxs(Paper, __assign({ css: styles.paper }, { children: [_jsx("p", { children: "\u3053\u306E\u30B5\u30A4\u30C8\u306F\u30D1\u30B9\u30EF\u30FC\u30C9\u304C\u8A2D\u5B9A\u3055\u308C\u3066\u3044\u307E\u3059" }), _jsx("p", { children: "\u30D1\u30B9\u30EF\u30FC\u30C9\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044" }), _jsxs("form", __assign({ noValidate: true, autoComplete: 'off', onSubmit: function (e) {
                        e.preventDefault();
                        onSubmit(password);
                    } }, { children: [_jsx(TextField, { variant: 'outlined', type: 'password', value: password, fullWidth: true, onChange: function (e) {
                                setPassword(e.target.value);
                            } }), isPasswordIncorrect && (_jsx("p", __assign({ css: styles.passwordIncorrect }, { children: "\u30D1\u30B9\u30EF\u30FC\u30C9\u304C\u9055\u3044\u307E\u3059" }))), _jsx("div", __assign({ css: styles.form }, { children: _jsx(Button, __assign({ type: 'submit', variant: 'contained', size: 'large', color: 'primary' }, { children: "OK" })) }))] }))] })) })));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
