var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import React from 'react';
var GoogleMap = function (_a) {
    var block = _a.block;
    var htmlString = block.html;
    var re = /<iframe[^>]+src="([^">]+)/g;
    var results = re.exec(htmlString);
    var url = results !== null && results[1];
    return (_jsx("div", __assign({ className: 'google-map-code' }, { children: _jsx("iframe", { title: 'Google Map', src: url === false ? '' : url, width: '100%', height: '320', style: { border: 0 }, allowFullScreen: false, loading: 'lazy', referrerPolicy: 'no-referrer-when-downgrade' }) })));
};
export default GoogleMap;
