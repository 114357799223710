import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import React from 'react';
import { useHtmlWithImageMeta } from './RichTextEditor';
import './RichTextEditor.scss';
var MultiRichTextEditor = function (_a) {
    var block = _a.block;
    return (_jsxs(_Fragment, { children: [' ', block.richTextList.map(function (richText, index) { return (_jsx(RichTextEditor, { blockColumns: block.columns, richText: richText }, index)); })] }));
    //
};
var RichTextEditor = function (_a) {
    var richText = _a.richText, blockColumns = _a.blockColumns;
    var html = useHtmlWithImageMeta(richText.html);
    return (_jsx("div", { className: "rte_block column_".concat(blockColumns), dangerouslySetInnerHTML: { __html: html } }));
};
export default MultiRichTextEditor;
