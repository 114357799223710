var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Dialog } from '@material-ui/core';
import React, { useState } from 'react';
import Slider from 'react-slick';
import Img from './Img';
var totalImages = 3;
var SlideShowBlockContent = function (_a) {
    var block = _a.block;
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var open = function () { return setIsOpen(true); };
    var close = function () { return setIsOpen(false); };
    var _c = useState(true), canShowImage = _c[0], setCanShowImage = _c[1];
    var _d = useState(''), showImageUrl = _d[0], setShowImageUrl = _d[1];
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: totalImages,
        slidesToScroll: 1,
        autoplay: true,
        swipeToSlide: true,
        afterChange: function () { return setCanShowImage(true); },
        beforeChange: function () { return setCanShowImage(false); },
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    autoplay: false,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: false,
                },
            },
        ],
    };
    var images = block.slideShowImages;
    return (_jsxs(_Fragment, { children: [images.length < totalImages ? (_jsx("div", __assign({ className: 'flex justify-center' }, { children: images.map(function (img, index) { return (_jsx(Img, { style: { minWidth: 0, margin: '10px', cursor: 'pointer' }, src: img, alt: '', className: 'inline-block  sm:min-w-0 w-11/12', loading: 'lazy', onClick: function () {
                        setShowImageUrl(img);
                        open();
                    } }, index)); }) }))) : (_jsx(Slider, __assign({}, settings, { children: images.map(function (img, index) { return (_jsx("div", { children: _jsx("p", { children: _jsx(Img, { className: 'w-full', style: { cursor: 'pointer' }, src: img, alt: '', loading: 'lazy', onClick: function () {
                                canShowImage &&
                                    (function () {
                                        setShowImageUrl(img);
                                        open();
                                    })();
                            } }) }) }, index)); }) }))), _jsx(Dialog, __assign({ open: isOpen, onClose: function () {
                    setShowImageUrl('');
                    close();
                }, maxWidth: 'md' }, { children: _jsx(Img, { src: showImageUrl }) }))] }));
};
export default SlideShowBlockContent;
