var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import React from 'react';
import ReactLoading from 'react-loading';
export function Loading(props) {
    var primaryColor = props.primaryColor;
    return (_jsxs("div", __assign({ style: { display: 'flex' } }, { children: [_jsx(ReactLoading, { className: 'page-loading', type: 'spokes', color: primaryColor, height: '20px', width: '20px' }), "\u8AAD\u8FBC\u4E2D..."] })));
}
