import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import useTrackClick from '../../api/use-track-click';
import { getSupportedImageMeta } from '../../utils/image-fallback';
import { replaceUrl } from '../../utils/url';
import './RichTextEditor.scss';
export var useHtmlWithImageMeta = function (html) {
    var convertedHtml = useMemo(function () {
        try {
            var parser = new DOMParser();
            var doc = parser.parseFromString(html, 'text/html');
            var images = doc.querySelectorAll('img');
            var tableHeaders = doc.querySelectorAll('th');
            if (images.length === 0 && tableHeaders.length === 0) {
                return html;
            }
            // 画像のサイズをsrcのファイル名から取得して、widthとheightを設定する
            images.forEach(function (image) {
                var src = image.getAttribute('src');
                var imageMeta = getSupportedImageMeta(src || '');
                if ((imageMeta === null || imageMeta === void 0 ? void 0 : imageMeta.width) && (imageMeta === null || imageMeta === void 0 ? void 0 : imageMeta.height)) {
                    image.setAttribute('width', imageMeta.width.toString());
                    image.setAttribute('height', imageMeta.height.toString());
                }
            });
            // テーブルのヘッダーの背景色がデフォルトの場合、背景色を削除してCSSで設定されたヘッダー用の背景色を適用する
            // テーブルのヘッダーの背景色が設定されている場合はそのままにする
            var DEFAULT_CELL_BACKGROUND_COLOR_1 = 'rgb(242, 243, 245)';
            tableHeaders.forEach(function (tableHeader) {
                var style = tableHeader.style.backgroundColor;
                if (style === DEFAULT_CELL_BACKGROUND_COLOR_1) {
                    tableHeader.style.backgroundColor = '';
                }
            });
            return doc.documentElement.innerHTML;
        }
        catch (e) {
            return html;
        }
    }, [html]);
    return convertedHtml;
};
var RichTextEditor = function (_a) {
    var block = _a.block;
    var html = useHtmlWithImageMeta(block.html);
    var siteUid = window.location.pathname.substring(1);
    var domain = window.location.hostname;
    var path = window.location.pathname;
    var trackClick = useTrackClick().trackClick;
    var searchParams = new URL(window.location.href).searchParams;
    var inflowSource = searchParams.get('_src') || window.document.referrer;
    var handleClick = useCallback(function (event) {
        var target = event.target;
        // targetの親がaタグになるまで探索してaタグを取得する
        while (target && target.tagName !== 'A') {
            target = target.parentElement;
        }
        if (!target) {
            return;
        }
        var url = target.getAttribute('href');
        if (!url || url.startsWith('#')) {
            return;
        }
        event.preventDefault();
        var replacedUrl = replaceUrl(url, inflowSource);
        trackClick(siteUid, url, domain, path, inflowSource).finally(function () {
            if (window == window.parent) {
                window.location.href = replacedUrl;
            }
            else {
                window.open(replacedUrl, '_blank');
            }
        });
    }, []);
    return (_jsx("div", { className: 'rte_block', dangerouslySetInnerHTML: { __html: html }, onClick: handleClick }));
};
export default RichTextEditor;
