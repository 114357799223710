var createMockShopReview = function (reviewId, relativeTime, comment) {
    return {
        reviewId: reviewId,
        rate: 5,
        comment: "".concat(comment, "(\u3053\u306E\u53E3\u30B3\u30DF\u306F\u30B5\u30F3\u30D7\u30EB\u3067\u3059\u3002)"),
        createTime: '',
        updateTime: '',
        relativeTime: relativeTime,
    };
};
export var mockShopReviews = [
    createMockShopReview('1', '1日前', '大変丁寧に接客いただきました。また利用させたいただきます。'),
    createMockShopReview('2', '3日前', '店内も清潔で気持ちよく過ごせました。'),
    createMockShopReview('3', '2週間前', 'ポイントシステムがかなりお得感があります。リピ確定です。'),
    createMockShopReview('4', '1ヶ月前', 'スタッフの方々がとても丁寧でいつも安心してサービスを受けることができます。'),
];
