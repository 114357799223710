var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import React from 'react';
var EmbeddedYouTube = function (_a) {
    var _b, _c;
    var block = _a.block;
    var htmlString = block.html;
    var re = /<iframe[^>]+src="([^">]+)/g;
    var results = re.exec(htmlString);
    var url = results !== null && results[1];
    return (_jsxs("section", __assign({ className: 'w-full lg:w-3/4 my-4 m-auto' }, { children: [_jsx("div", __assign({ className: 'aspect-w-16 aspect-h-9' }, { children: _jsx("iframe", { loading: 'lazy', title: 'YouTube', src: url === false ? '' : url, frameBorder: '0', allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture', allowFullScreen: true }) })), block.title && (_jsx("p", __assign({ className: 'text-2xl font-bold my-3 break-all', style: {
                    textAlign: (_b = block.titleTextAlign) !== null && _b !== void 0 ? _b : 'center',
                } }, { children: block.title }))), block.description && (_jsx("p", __assign({ className: 'break-all', style: {
                    textAlign: (_c = block.descriptionTextAlign) !== null && _c !== void 0 ? _c : 'left',
                } }, { children: block.description })))] })));
};
export default EmbeddedYouTube;
