var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import React, { useEffect, useMemo } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import FooterConversionButton from '../components/FooterConversionButton';
import Header from '../components/Header';
import SiteContents from '../components/SiteContents';
import HeroBlock from '../features/heroBlock/routes/HeroBlock';
import { insertUserScripts } from '../utils/browserUtils';
var styles = {
    main: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 100%;\n    overflow: hidden;\n    margin: 0;\n    text-align: center;\n    background-color: #f2f2f2;\n  "], ["\n    width: 100%;\n    overflow: hidden;\n    margin: 0;\n    text-align: center;\n    background-color: #f2f2f2;\n  "]))),
};
var SitePage = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    var uid = _a.uid, setting = _a.setting, locationJson = _a.locationJson, shopReviews = _a.shopReviews, icons = _a.icons, seoSetting = _a.seoSetting;
    useEffect(function () {
        var _a, _b;
        // GA等のスクリプトの読み込み(HEADタグ末尾)
        if ((_a = setting.userScript) === null || _a === void 0 ? void 0 : _a.head) {
            insertUserScripts((_b = setting.userScript) === null || _b === void 0 ? void 0 : _b.head, 'head_last');
        }
    }, [(_b = setting.userScript) === null || _b === void 0 ? void 0 : _b.head]);
    useEffect(function () {
        var _a, _b;
        // GA等のスクリプトの読み込み(BODYタグ先頭)
        if ((_a = setting.userScript) === null || _a === void 0 ? void 0 : _a.body) {
            insertUserScripts((_b = setting.userScript) === null || _b === void 0 ? void 0 : _b.body, 'body_first');
        }
    }, [(_c = setting.userScript) === null || _c === void 0 ? void 0 : _c.body]);
    //構造化データ
    var schema = locationJson && {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        name: locationJson.title || '',
        address: {
            '@type': 'PostalAddress',
            streetAddress: ((_e = (_d = locationJson.storefrontAddress) === null || _d === void 0 ? void 0 : _d.addressLines) === null || _e === void 0 ? void 0 : _e[1]) || '',
            addressLocality: ((_g = (_f = locationJson.storefrontAddress) === null || _f === void 0 ? void 0 : _f.addressLines) === null || _g === void 0 ? void 0 : _g[0]) || '',
            addressRegion: ((_h = locationJson.storefrontAddress) === null || _h === void 0 ? void 0 : _h.administrativeArea) || '',
            postalCode: ((_j = locationJson.storefrontAddress) === null || _j === void 0 ? void 0 : _j.postalCode) || '',
            addressCountry: ((_k = locationJson.storefrontAddress) === null || _k === void 0 ? void 0 : _k.regionCode) || '',
        },
        telephone: ((_l = locationJson.phoneNumbers) === null || _l === void 0 ? void 0 : _l.primaryPhone) || '',
    };
    var templateMode = useMemo(function () {
        var siteTemplateKey = new URLSearchParams(window.location.search).get('_siteTemplateKey');
        return siteTemplateKey !== null;
    }, [window.location.search]);
    return (_jsxs(HelmetProvider, { children: [_jsxs(Helmet, { children: [_jsx("link", { rel: 'icon', href: (seoSetting === null || seoSetting === void 0 ? void 0 : seoSetting.faviconUrl) || '/images/favicon.ico' }), ((seoSetting === null || seoSetting === void 0 ? void 0 : seoSetting.title) && _jsx("title", { children: seoSetting.title })) ||
                        (setting.title && _jsx("title", { children: setting.title })), ((seoSetting === null || seoSetting === void 0 ? void 0 : seoSetting.description) && (_jsx("meta", { name: 'description', content: seoSetting.description }))) ||
                        (setting.description && (_jsx("meta", { name: 'description', content: setting.description }))), setting.bgImage && (_jsx("link", { rel: 'preload', as: 'image', href: setting.bgImage })), setting.bgSpImage && (_jsx("link", { rel: 'preload', as: 'image', href: setting.bgSpImage })), locationJson && (_jsx("script", __assign({ type: 'application/ld+json' }, { children: JSON.stringify(schema) }))), templateMode || process.env.REACT_APP_STAGE === 'stg' ? (_jsx("meta", { name: 'robots', content: 'noindex, nofollow' })) : (seoSetting === null || seoSetting === void 0 ? void 0 : seoSetting.robots) ? (_jsx("meta", { name: 'robots', content: seoSetting.robots })) : ('')] }), setting.headerFrag && _jsx(Header, { setting: setting, icons: icons }), _jsxs("main", __assign({ css: styles.main, className: 'global-main' }, { children: [_jsx(HeroBlock, { setting: setting }), _jsx(SiteContents, { uid: uid, setting: setting, shopReviews: shopReviews, icons: icons, locationJson: locationJson }, uid), !setting.headerFrag && (_jsx(FooterConversionButton, { footerCvButtons: setting.footerCvButtons, icons: icons, setting: setting }))] }))] }));
};
export default SitePage;
var templateObject_1;
