var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import React, { useEffect } from 'react';
var EmbeddedInstagram = function (_a) {
    var block = _a.block;
    var htmlString = block.html;
    var blockQuote = htmlString.slice(htmlString.indexOf('<blockquote'), htmlString.indexOf('</blockquote>'));
    useEffect(function () {
        // Instagramのscriptを読み込み
        var script = document.createElement('script');
        script.src = 'https://www.instagram.com/embed.js';
        document.body.appendChild(script);
        // アンマウント時に一応scriptタグを消しておく
        return function () {
            document.body.removeChild(script);
        };
    }, []);
    return (_jsx("section", __assign({ className: 'w-full lg:w-3/4 my-4 m-auto flex justify-center' }, { children: _jsx("div", { dangerouslySetInnerHTML: { __html: blockQuote } }) })));
};
export default EmbeddedInstagram;
