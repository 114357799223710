import { useEffect, useState } from 'react';
import { writeThemeColorStylesToHead } from '../utils/SiteHelper';
var useSiteDesign = function (setting) {
    var _a = useState(true), progressSiteDesign = _a[0], setProgressSiteDesign = _a[1];
    useEffect(function () {
        if (!setting) {
            return;
        }
        setProgressSiteDesign(true);
        writeThemeColorStylesToHead(setting);
        setProgressSiteDesign(false);
    }, [setting]);
    return { progressSiteDesign: progressSiteDesign };
};
export default useSiteDesign;
