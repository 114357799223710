import chroma from 'chroma-js';
export var replacePlaceholder = function (form, template) {
    var _a;
    if (!template) {
        return template;
    }
    var placeholders = Array.from(template.matchAll(/\${([a-zA-Z0-9]+)}/g));
    var _loop_1 = function (placeholder) {
        var fieldName = placeholder[1];
        var fieldValue = form.fieldValues.find(function (f) { return f.fieldUid === fieldName; });
        var value = (fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.value) || ((_a = fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.values) === null || _a === void 0 ? void 0 : _a.join(','));
        // まだ対象フィールドが未設定の場合、undedinedを返してデフォルト値を表示させる。
        if (!value) {
            return { value: undefined };
        }
        template = template === null || template === void 0 ? void 0 : template.replace(placeholder[0], value);
    };
    for (var _i = 0, placeholders_1 = placeholders; _i < placeholders_1.length; _i++) {
        var placeholder = placeholders_1[_i];
        var state_1 = _loop_1(placeholder);
        if (typeof state_1 === "object")
            return state_1.value;
    }
    return template;
};
export var darkThemeColor = function (enqueteSetting) {
    if (!enqueteSetting.themeColor) {
        return undefined;
    }
    return chroma(enqueteSetting.themeColor).darken(1).hex();
};
export var writeThemeColorStylesToHead = function (setting) {
    var themeColor = setting.themeColor;
    if (themeColor) {
        var dark = darkThemeColor(setting);
        var style = document.createElement('style');
        style.textContent = "\n\n        button {\n          background: ".concat(themeColor, ";\n        }\n\n        .site .site-title {\n          color:").concat(themeColor, ";\n        }\n\n        .site .name_section__input, .site .input-text input[type='text'], .site textarea{\n          border-color:").concat(themeColor, ";\n        }\n\n        .site .link {\n          background-color: ").concat(themeColor, ";\n          border-color:").concat(themeColor, ";\n        }\n\n        .site .theme-color {\n          color:").concat(themeColor, ";\n        }\n\n        .site .theme-background-color {\n          background-color: ").concat(themeColor, ";\n        }\n        ");
        document.head.appendChild(style);
    }
};
