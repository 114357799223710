var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import Box from '@material-ui/core/Box';
import React, { useMemo } from 'react';
import { lineBreakToParagraphAndHtml as LB2PAndH } from '../../../utils/browserUtils';
import { scrollMessageSenderStyle, createScrollMessageSender, } from '../../../utils/scroll-message-sender';
var styles = {
    rightArea: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: absolute !important;\n    top: 8%;\n    right: 0;\n    left: auto !important;\n    text-align: right;\n    max-width: 68%;\n    padding: 0 1rem;\n    @media screen and (min-width: 769px) {\n      position: absolute;\n      top: 12%;\n      width: 32%;\n      font-size: 1.2rem;\n    }\n  "], ["\n    position: absolute !important;\n    top: 8%;\n    right: 0;\n    left: auto !important;\n    text-align: right;\n    max-width: 68%;\n    padding: 0 1rem;\n    @media screen and (min-width: 769px) {\n      position: absolute;\n      top: 12%;\n      width: 32%;\n      font-size: 1.2rem;\n    }\n  "]))),
};
export var RightAreaTextBlock = function (_a) {
    var catchphrase = _a.catchphrase;
    if (catchphrase === null || catchphrase === void 0 ? void 0 : catchphrase.rightAreaText) {
        var rightAreaText = catchphrase.rightAreaText, rightAreaBgColor_1 = catchphrase.rightAreaBgColor, rightAreaBgOpacity_1 = catchphrase.rightAreaBgOpacity, rightAreaTextColor = catchphrase.rightAreaTextColor;
        var backgroundStyles = useMemo(function () {
            var red = parseInt(rightAreaBgColor_1.substring(1, 3), 16);
            var green = parseInt(rightAreaBgColor_1.substring(3, 5), 16);
            var blue = parseInt(rightAreaBgColor_1.substring(5, 7), 16);
            var alpha = rightAreaBgOpacity_1;
            return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        background-color: rgba(", ", ", ", ", ", ", ");\n      "], ["\n        background-color: rgba(", ", ", ", ", ", ", ");\n      "])), red, green, blue, alpha);
        }, [rightAreaBgColor_1, rightAreaBgOpacity_1]);
        return (_jsx(Box, __assign({ css: [backgroundStyles, styles.rightArea, scrollMessageSenderStyle()], onClick: createScrollMessageSender('catchphrase') }, { children: _jsx("div", __assign({ css: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n            color: ", ";\n          "], ["\n            color: ", ";\n          "])), rightAreaTextColor) }, { children: LB2PAndH(rightAreaText) })) })));
    }
    else {
        return null;
    }
};
var templateObject_1, templateObject_2, templateObject_3;
