var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import React from 'react';
import Img from '../../../components/Img';
// アイコン画像
export var IconImageBlock = function (_a) {
    var iconImage = _a.iconImage, bgImage = _a.bgImage, isHeaderDisabled = _a.isHeaderDisabled;
    if (isHeaderDisabled && iconImage) {
        return (_jsx("p", __assign({ className: "m-auto overflow-hidden w-28 h-28 shadow-md rounded-full ".concat(bgImage ? '-mt-10' : 'mt-4', " z-10 bg-white relative flex items-center justify-center") }, { children: _jsx(Img, { src: iconImage, alt: '' }) })));
    }
    else
        return null;
};
