var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import React, { useCallback } from 'react';
import { Rating } from 'react-simple-star-rating';
import useTrackClick from '../api/use-track-click';
import { mockShopReviews } from '../constants/mockShopReviews';
import Review from './Review';
var WordOfMouth = function (_a) {
    var shopReviews = _a.shopReviews, block = _a.block, setting = _a.setting;
    var siteUid = window.location.pathname.substring(1);
    var domain = window.location.hostname;
    var path = window.location.pathname;
    var trackClick = useTrackClick().trackClick;
    var searchParams = new URL(window.location.href).searchParams;
    var inflowSource = searchParams.get('_src') || window.document.referrer;
    var handleClick = useCallback(function (event, url, trackingUrl) {
        event.preventDefault();
        trackClick(siteUid, trackingUrl || url, domain, path, inflowSource).finally(function () {
            if (window == window.parent) {
                window.location.href = url;
            }
            else {
                window.open(url, '_blank');
            }
        });
    }, []);
    return (_jsxs(_Fragment, { children: [shopReviews.rate && shopReviews.reviewCount ? (_jsxs(_Fragment, { children: [_jsx("h2", __assign({ className: 'my-4 text-2xl font-bold' }, { children: "Google\u306E\u53E3\u30B3\u30DF" })), _jsxs("p", __assign({ className: 'my-4 text-2xl flex justify-center items-center' }, { children: [_jsx(Rating, { ratingValue: shopReviews.rate * 20, size: 50, allowHalfIcon: true, allowHover: false, style: { cursor: 'default' }, onClick: function () { return null; }, readonly: true }), _jsxs("span", { children: ["(", _jsx("a", __assign({ className: 'hover:underline', href: shopReviews.mapsUrl, onClick: function (event) {
                                            if (!shopReviews.isConnectedApi) {
                                                return;
                                            }
                                            handleClick(event, shopReviews.mapsUrl);
                                        } }, { children: shopReviews.reviewCount })), ")"] })] })), _jsx("div", __assign({ className: 'grid grid-cols-1 lg:grid-cols-2 gap-16' }, { children: shopReviews.reviews.map(function (shopReview, index) { return (_jsx(Review, { block: block, shopReview: shopReview }, "shop-review-".concat(index))); }) })), _jsx("a", __assign({ href: shopReviews.mapsUrl, onClick: function (event) { return handleClick(event, shopReviews.mapsUrl); } }, { children: _jsx("button", __assign({ css: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n                background: none;\n              "], ["\n                background: none;\n              "]))), className: 'hover:opacity-80 rounded-full border-2 border-solid px-4 py-2 my-8' }, { children: "\u5168\u3066\u306E\u53E3\u30B3\u30DF\u3092\u898B\u308B" })) }))] })) : null, shopReviews.isConnectedApi ? (_jsx(_Fragment, {})) : (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: 'error' }, { children: "Google\u30D3\u30B8\u30CD\u30B9\u30D7\u30ED\u30D5\u30A3\u30FC\u30EB\u30A2\u30AB\u30A6\u30F3\u30C8\u3068\u63A5\u7D9A\u3055\u308C\u3066\u3044\u306A\u3044\u305F\u3081\u53E3\u30B3\u30DF\u3092\u8868\u793A\u3067\u304D\u307E\u305B\u3093\u3002 Locaop\u306E\u7BA1\u7406\u753B\u9762\u304B\u3089\u63A5\u7D9A\u5F8C\u306B\u3053\u306EURL\u306F\u516C\u958B\u3057\u3066\u304F\u3060\u3055\u3044\u3002\u4EE5\u4E0B\u306F\u53E3\u30B3\u30DF\u8868\u793A\u306E\u30B5\u30F3\u30D7\u30EB\u3067\u3059\u3002" })), _jsx("div", __assign({ className: 'reviews' }, { children: mockShopReviews.map(function (mockShopReview, index) { return (_jsx(Review, { shopReview: mockShopReview, block: block }, "mock-shop-review-".concat(index))); }) })), _jsx("div", __assign({ className: 'read-more' }, { children: _jsx("a", __assign({ href: 'javascript: void(0)', className: 'theme-color' }, { children: _jsx("button", __assign({ style: {
                                    borderColor: setting.themeColor,
                                    color: setting.themeColor,
                                }, className: 'hover:opacity-80 rounded-full border-2 border-solid bg-white px-4 py-2 my-8' }, { children: "\u5168\u3066\u306E\u53E3\u30B3\u30DF\u3092\u898B\u308B" })) })) }))] }))] }));
};
export default WordOfMouth;
var templateObject_1;
