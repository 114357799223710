var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import React from 'react';
var SvgIcon = function (_a) {
    var icon = _a.icon, color = _a.color, fontSize = _a.fontSize, icons = _a.icons;
    if (!icon) {
        return null;
    }
    var svgIcon = icons[icon];
    if (svgIcon) {
        var width = svgIcon.width, height = svgIcon.height, svgPaths = svgIcon.svgPaths;
        return (_jsx("div", __assign({ style: { fontSize: "".concat(fontSize, "px"), color: color, padding: '2px' } }, { children: _jsx("svg", __assign({ stroke: 'currentColor', fill: 'currentColor', strokeWidth: '0', viewBox: "0 0 ".concat(width, " ").concat(height), height: '1em', width: '1em', xmlns: 'http://www.w3.org/2000/svg' }, { children: svgPaths.map(function (svgPath, index) {
                    return _jsx("path", { d: svgPath }, index);
                }) })) })));
    }
    else {
        return null;
    }
};
export default SvgIcon;
